<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <!-- 控制区域 -->
      <div class="control_box">
        <div class="control_box_top">
          <!-- 这个页面的时间选择器比较特殊就不用封装的搜索组件  :disabledDate="rangeLimit"-->
          <a-range-picker
            @change="onChangeTimeRange"
            :placeholder="['开始时间', '结束时间']"
            v-model="timeData"
            :allowClear="false"
            style="margin-right:10px"
          />
          <a-button
            type="primary"
            icon="search"
            style="margin-right:10px"
            @click="getInstReportFn"
          >生成</a-button>
          <a-button icon="sync" @click="reflashFn">刷新</a-button>
        </div>
        <div class="control_box_bottom">
          <a-button
            type="primary"
            icon="printer"
            @click="downloadExcel"
            :disabled="tableData.length == 0 || loading"
          >导出</a-button>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table
          :loading="loading"
          :columns="columns"
          :data-source="tableData"
          bordered
          :pagination="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getInstReport, userLog } from "@/request/api";
import moment from "moment";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { SearchBar, MyBreadcrumb },
  data() {
    // 表格的列配置
    const columns = [
      {
        title: "消费类别",
        dataIndex: "consumptionType",
        customRender: (value, row, index) => {
          let tableLength = this.tableDataLength;
          const obj = {
            children: value,
            attrs: {},
          };
          if (row.key === tableLength) {
            obj.attrs.colSpan = 3;
          }
          return obj;
        },
      },
      {
        title: "项目名称",
        colSpan: 2,
        dataIndex: "goodType",
        customRender: (value, row, index) => {
          let tableLength = this.tableDataLength;
          const obj = {
            children: value,
            attrs: {},
          };
          if (row.goodTitle == "") {
            obj.attrs.colSpan = 2;
          }
          if (row.key === tableLength) {
            obj.attrs.colSpan = 0;
          }
          return obj;
        },
      },
      {
        title: "项目名称",
        colSpan: 0,
        dataIndex: "goodTitle",
        customRender: (value, row, index) => {
          let tableLength = this.tableDataLength;
          const obj = {
            children: value,
            attrs: {},
          };
          if (row.goodTitle == "") {
            obj.attrs.colSpan = 0;
          }
          if (row.key === tableLength) {
            obj.attrs.colSpan = 0;
          }
          return obj;
        },
      },
      {
        title: "数量",
        dataIndex: "num",
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {},
          };
          return obj;
        },
      },
      {
        title: "金额",
        dataIndex: "total_money",
        customRender: (value, row, index) => {
          const obj = {
            children: value,
            attrs: {},
          };
          return obj;
        },
      },
    ];

    return {
      breadcrumbs: [
        {
          key: 0,
          title: "经营报表",
        },
        {
          key: 1,
          title: "食堂经营报告",
        },
      ],
      timeParam: {
        begin_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      tableDataLength: 0,
      loading: false,
      columns,
      timeData: [
        moment(this.getCurrentData(), "YYYY-MM-DD"),
        moment(this.getCurrentData(), "YYYY-MM-DD"),
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    moment,
    //可选时间限制(仅当天之前的31天内可选)
    // rangeLimit(time) {
    //   // console.log(time)
    //   if (!time) {
    //     return false;
    //   } else {
    //     return time < moment(this.timeParam.begin_date).subtract(31, "days") || time > moment();
    //     // return time < this.startMoment.subtract(31, "days") || time > this.startMoment;
    //     // return time > moment();

    //   }
    // },
    //列表下载
    downloadExcel() {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => {},
      });
    },
    //数据写入excel
    export2Excel() {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        // const tHeader = ["消费类别", "项目类别", "项目名称", "数量", "金额"]; // 导出的表头名信息
        const tHeader = [
          ["#起始日期", this.timeParam.begin_date, "#终止日期", this.timeParam.end_date, ""],
          ["消费类别", "项目类别", "项目名称", "数量", "金额"]
        ]; // 导出的表头名信息
        const filterVal = [
          "consumptionType",
          "goodType",
          "goodTitle",
          "num",
          "total_money",
        ]; // 导出的表头字段名，需要导出表格字段名
        const list = that.tableData;
        const data = that.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "食堂经营报告"); // 导出的表格名称，根据需要自己命名
        this.userLogFn()
      });
    },
    //格式转换
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    // 刷新
    reflashFn() {
      this.timeData = [
        moment(this.getCurrentData(), "YYYY-MM-DD"),
        moment(this.getCurrentData(), "YYYY-MM-DD"),
      ];
      this.timeParam = {
        begin_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      };
      this.getInstReportFn();
    },
    getCurrentData() {
      return new Date().toLocaleDateString();
    },
    //获取表格数据
    getInstReportFn() {
      this.loading = true;
      getInstReport(this.timeParam).then((res) => {
        //整理接口返回的数据
        let resultData = res.data,
          stores = [],
          meals = [],
          expenses = [];
        if (
          resultData.expenses.length == 0 &&
          resultData.stores.length == 0 &&
          resultData.meals.length == 0
        ) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        if (resultData.stores.length > 0) {
          stores = resultData.stores.map((item) => {
            item = {
              consumptionType: "小卖部",
              goodType: item.goods_type,
              goodTitle: item.goods_title,
              num: item.num,
              total_money: item.total_money,
            };
            return item;
          });
        }
        if (resultData.meals.length > 0) {
          meals = resultData.meals.map((item) => {
            item = {
              consumptionType: "用餐",
              goodType: item.meal_type,
              goodTitle: item.meal_title,
              num: item.num,
              total_money: item.total_money,
            };
            return item;
          });
        }
        if (resultData.expenses.length > 0) {
          expenses = resultData.expenses.map((item) => {
            item = {
              consumptionType: "临时消费",
              goodType: item.type_name,
              goodTitle: "",
              num: item.num,
              total_money: item.total_money,
            };
            return item;
          });
        }
        let totalObj = {
            consumptionType: "合计（全部人员）",
            goodType: "",
            goodTitle: "",
            num: resultData.total_num,
            total_money: resultData.total_money,
          },
          formatData = stores.concat(meals).concat(expenses),
          key = 1;
        formatData.push(totalObj);
        formatData.map((item) => {
          item.key = key++;
          return item;
        });
        this.loading = false;
        this.tableData = formatData;
        this.tableDataLength = formatData.length;
      });
    },
    //时间选择
    onChangeTimeRange(date, dateString) {
      let startDate = new Date(dateString[0]).getTime(),
        endDate = new Date(dateString[1]).getTime();
      if (startDate + 3600 * 1000 * 24 * 30 < endDate) {
        this.timeData = [
          moment(this.getCurrentData(), "YYYY-MM-DD"),
          moment(this.getCurrentData(), "YYYY-MM-DD"),
        ];
        this.timeParam = {
          begin_date: moment().format("YYYY-MM-DD"),
          end_date: moment().format("YYYY-MM-DD"),
        };
        this.$message.info("日期范围最大为31天");
      } else {
        this.timeParam = {
          begin_date: dateString[0],
          end_date: dateString[1],
        };
      }
    },

    userLogFn(){
      let moduleId = this.$route.meta.moduleId,
          param = {
            begin_date:this.timeParam.begin_date,
            end_date:this.timeParam.end_date,
            total:this.tableData.length
          };
      userLog(moduleId,param).then(res => {}).catch(err => {
        console.log(err)
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getInstReportFn();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}

//修改列表文字、背景颜色
/deep/.ant-table-tbody > tr > td {
  // border-bottom: 10px solid #f0f2f5 !important;
  background: white !important;
}
</style>